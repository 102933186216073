import React, { useCallback, useEffect, useState, useRef } from 'react';

export const RestOverlay = ({ onMoveCompletion = () => {} }) => {
  const [restComplete, setRestComplete] = useState(false);
  const btnRef = useRef();

  const handleClick = useCallback(() => {
    setRestComplete(() => true);
  }, []);

  useEffect(() => {
    if (restComplete === true) {
      // handle any additional actions, otherwise we can hide the overlay
      onMoveCompletion();
    }
  }, [restComplete, onMoveCompletion]);

  useEffect(() => {
    btnRef.current.focus();
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(138, 251, 253, 0.5)',
      }}
    >
      <h1>Rest Overlay</h1>

      <button ref={btnRef} onClick={handleClick}>
        Rested
      </button>
    </div>
  );
};
