import React from 'react';
import { Link } from 'react-router-dom';
import './HomeScreen.module.css';

export const HomeScreen = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        textAlign: 'center',
      }}
    >
      <h1>Edges & Arrows</h1>
      <p>Click on the buttons below to navigate to the respective screens</p>
      <br />
      <Link to="/play">Play</Link>
      <br />
      <Link to="/how-to-play">How to Play</Link>
      <br />
      <Link to="/settings">Settings</Link>
      <br />
      <Link to="/about">About</Link>
    </div>
  );
};
