import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Player } from './Player';
import { animate, useMotionValue } from 'framer-motion';
import { EdgeOverlay } from './overlays/EdgeOverlay';
import { TaskOverlay } from './overlays/TaskOverlay';
import { RestOverlay } from './overlays/RestOverlay';
import { DoubleNextEdgeOverlay } from './overlays/DoubleNextEdgeOverlay';
import { HalfNextRollOverlay } from './overlays/HalfNextRollOverlay';
import { GLOBAL_ZOOM } from './constants';
import { Helmet } from 'react-helmet';

const background = `${process.env.PUBLIC_URL}/background.`;

/**
 * tile array is 0-indexed
 */

// Updated coordinates for the first three tiles
const tiles = [
  { id: 0, x: 1988, y: 3764, value: 5 },
  { id: 1, x: 1720, y: 3580, value: 2 },
  { id: 2, x: 1990, y: 3430, value: 4 },
  { id: 3, x: 2256, y: 3266, value: 3 },
  { id: 4, x: 2488, y: 3080, value: 'task' },
  { id: 5, x: 2234, y: 2946, value: 6 },
  { id: 6, x: 1948, y: 2782, value: 7 },
  { id: 7, x: 1700, y: 2936, value: 'double-next-edge' },
  { id: 8, x: 1428, y: 3080, value: 6, arrow: 2 },
  { id: 9, x: 1152, y: 3238, value: 2 },
  { id: 10, x: 898, y: 3362, value: 'task' },
  { id: 11, x: 640, y: 3220, value: 10 },
  { id: 12, x: 394, y: 3054, value: 4 },
  { id: 13, x: 656, y: 2908, value: 5 },
  { id: 14, x: 924, y: 2758, value: 3 },
  { id: 15, x: 1190, y: 2608, value: 'task' },
  { id: 16, x: 924, y: 2478, value: 5 },
  { id: 17, x: 664, y: 2310, value: 7, arrow: 13 },
  { id: 18, x: 410, y: 2160, value: 1 },
  { id: 19, x: 670, y: 2002, value: 4 },
  { id: 20, x: 932, y: 1864, value: 6 },
  { id: 21, x: 1184, y: 2012, value: 3 },
  { id: 22, x: 1450, y: 2156, value: 'half-next-roll' },
  { id: 23, x: 1716, y: 2310, value: 8 },
  { id: 24, x: 1968, y: 2150, value: 5, arrow: 6 },
  { id: 25, x: 2230, y: 1998, value: 6 },
  { id: 26, x: 2478, y: 1826, value: 1 },
  { id: 27, x: 2224, y: 1660, value: 'task' },
  { id: 28, x: 1968, y: 1528, value: 3 },
  { id: 29, x: 1706, y: 1672, value: 2, arrow: 21 },
  { id: 30, x: 1442, y: 1510, value: 5 },
  { id: 31, x: 1170, y: 1348, value: 7 },
  { id: 32, x: 910, y: 1498, value: 'rest' },
  { id: 33, x: 640, y: 1330, value: 2, arrow: 19 },
  { id: 34, x: 384, y: 1178, value: 4 },
  { id: 35, x: 640, y: 1018, value: 'task' },
  { id: 36, x: 880, y: 884, value: 12 },
  { id: 37, x: 1170, y: 724, value: 8 },
  { id: 38, x: 1406, y: 872, value: 3, arrow: 31 },
  { id: 39, x: 1670, y: 1012, value: 7 },
  { id: 40, x: 1932, y: 1150, value: 5 },
  { id: 41, x: 2178, y: 1002, value: 9 },
  { id: 42, x: 2430, y: 858, value: 13 },
  { id: 43, x: 2172, y: 694, value: 6, arrow: 39 },
  { id: 44, x: 1912, y: 544, value: 'ruin' },
  { id: 45, x: 1714, y: 420, value: 'finish' },
];

const initialStep = 2; // Initial step size for smoother start

const Board = () => {
  const [currentTile, setCurrentTile] = useState(0);
  const animationFrameId = useRef(null);

  // track whether the player is currently completing a move
  const [isMoving, setIsMoving] = useState(false);

  // store the current number of edges the player must complete for the tile they've landed on.
  const [edgesForCurrentMove, setEdgesForCurrentMove] = useState(0);

  const [currentMove, setCurrentMove] = useState(null);
  const [remainder, setRemainder] = useState(null);
  const [direction, setDirection] = useState({ x: 0, y: 0 });

  /**
   * Overlay UI Display Flags
   */
  const [shouldDisplayEdgeOverlay, setShouldDisplayEdgeOverlay] =
    useState(false);
  const [shouldDisplayTaskOverlay, setShouldDisplayTaskOverlay] =
    useState(false);
  const [
    shouldDisplayDoubleNextEdgeOverlay,
    setShouldDisplayDoubleNextEdgeOverlay,
  ] = useState(false);
  const [shouldDisplayRestOverlay, setShouldDisplayRestOverlay] =
    useState(false);
  const [
    shouldDisplayHalfNextRollOverlay,
    setShouldDisplayHalfNextRollOverlay,
  ] = useState(false);

  const scrollX = useMotionValue(0);
  const scrollY = useMotionValue(0);

  const centerViewportOnTile = useCallback(
    (tileX, tileY, useAnimation = true) => {
      const viewportWidth = viewportRef.current.clientWidth;
      const viewportHeight = viewportRef.current.clientHeight;

      const boardWidth = 2896 * GLOBAL_ZOOM;
      const boardHeight = 4096 * GLOBAL_ZOOM;

      // Calculate the new scroll position
      const newScrollX = tileX * GLOBAL_ZOOM - viewportWidth / 2;
      const newScrollY = tileY * GLOBAL_ZOOM - viewportHeight / 2;

      // Make sure the new scroll position is within the board boundaries
      const maxScrollX = boardWidth - viewportWidth;
      const maxScrollY = boardHeight - viewportHeight;

      const finalScrollX = Math.max(0, Math.min(newScrollX, maxScrollX));
      const finalScrollY = Math.max(0, Math.min(newScrollY, maxScrollY));

      if (useAnimation) {
        // Animate the scroll position
        animate(scrollX, finalScrollX);
        animate(scrollY, finalScrollY);
      } else {
        // Set the scroll position without animation
        scrollX.set(finalScrollX);
        scrollY.set(finalScrollY);
        viewportRef.current.scrollLeft = scrollX.get();
        viewportRef.current.scrollTop = scrollY.get();
      }
    },
    [scrollX, scrollY]
  );

  useEffect(() => {
    centerViewportOnTile(tiles[0].x, tiles[0].y, false);
  }, [centerViewportOnTile]);

  useEffect(() => {
    const unsubscribeX = scrollX.on('change', (value) => {
      viewportRef.current.scrollLeft = value;
    });

    const unsubscribeY = scrollY.on('change', (value) => {
      viewportRef.current.scrollTop = value;
    });

    return () => {
      unsubscribeX();
      unsubscribeY();
    };
  }, [scrollX, scrollY]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'Tab': {
          e.preventDefault();
          if (!isMoving) {
            setIsMoving(true);
            setCurrentTile((prev) => {
              /**
               * If we are at the last tile, go back to the first tile
               */
              const nextTile = (prev + 1) % tiles.length;
              // centerCameraOnTile(nextTile, 'forward');
              centerViewportOnTile(tiles[nextTile].x, tiles[nextTile].y);
              setTimeout(() => setIsMoving(false), 500);
              return nextTile;
            });
          }

          break;
        }
        case 'ArrowUp':
          setDirection((prev) => ({ ...prev, y: -1 }));
          break;
        case 'ArrowDown':
          setDirection((prev) => ({ ...prev, y: 1 }));
          break;
        case 'ArrowLeft':
          setDirection((prev) => ({ ...prev, x: -1 }));
          break;
        case 'ArrowRight':
          setDirection((prev) => ({ ...prev, x: 1 }));
          break;
        default:
          break;
      }
    };

    const handleKeyUp = (e) => {
      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowDown':
          setDirection((prev) => ({ ...prev, y: 0 }));
          break;
        case 'ArrowLeft':
        case 'ArrowRight':
          setDirection((prev) => ({ ...prev, x: 0 }));
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [centerViewportOnTile, isMoving, setIsMoving]);

  useEffect(() => {
    const animate = () => {
      if (direction.x !== 0 || direction.y !== 0) {
        viewportRef.current.scrollBy(
          direction.x * initialStep,
          direction.y * initialStep
        );
        animationFrameId.current = requestAnimationFrame(animate);
      }
    };

    animationFrameId.current = requestAnimationFrame(animate);

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [direction]);

  const { x, y } = tiles[currentTile] || { x: 0, y: 0 };

  const viewportRef = useRef();

  const onFullScreenClick = useCallback(() => {
    if (viewportRef.current.requestFullscreen) {
      viewportRef.current.requestFullscreen();
    } else if (viewportRef.current.webkitRequestFullscreen) {
      /* Safari */
      viewportRef.current.webkitRequestFullscreen();
    } else if (viewportRef.current.msRequestFullscreen) {
      /* IE11 */
      viewportRef.current.msRequestFullscreen();
    }
  }, []);

  const processTileAction = useCallback((tile) => {
    switch (tile.value) {
      case 'task': {
        setShouldDisplayTaskOverlay(true);
        break;
      }
      case 'double-next-edge': {
        setShouldDisplayDoubleNextEdgeOverlay(true);
        /**
         * @todo track when the player lands on an edge tile and double the edge value
         */
        setIsMoving(false);
        break;
      }
      case 'half-next-roll': {
        setShouldDisplayHalfNextRollOverlay(true);
        setIsMoving(false);
        break;
      }
      case 'rest': {
        setShouldDisplayRestOverlay(true);
        setIsMoving(false);
        break;
      }
      case 'ruin': {
        alert('Ruin');
        setIsMoving(false);
        break;
      }
      case 'finish': {
        alert('Finish');
        break;
      }
      default: {
        // tile value should be a number
        if (typeof tile.value !== 'number') {
          throw new Error('Invalid tile value');
        }

        // shouldDisplayEdgeOverlay = true;
        setEdgesForCurrentMove(tile.value);
        setShouldDisplayEdgeOverlay(true);

        break;
      }
    }
  }, []);

  const diceRoll = useCallback(() => {
    setIsMoving(true);

    /**
     * Generate a random number between 1 and 6
     */
    let steps = Math.floor(Math.random() * 6) + 1;

    if (shouldDisplayHalfNextRollOverlay) {
      // divide steps by 2, rounding down to the nearest whole, non-zero number
      steps = Math.floor(steps / 2);
      if (steps === 0 || steps < 0) {
        steps = 1;
      }
    }

    // setShouldDisplayHalfNextRollOverlay(false);

    setCurrentMove(steps);

    // if there are less steps to move than the dice roll, set the remainder count to the steps
    const remainder = steps - (tiles.length - currentTile - 1);

    if (remainder > 0) {
      setRemainder(remainder);
    }

    // write code that
    // builds an array with the x and y coordinates of each subsequent tile from the currentTile.
    const stepsToMove = tiles.slice(currentTile + 1, currentTile + steps + 1);

    /**
     * @deprecated We now want to move the player down an arrow *after* the so-called "action" phase
     * when the player lands on the last tile of their move.
     * On completion of the action, move the player to the arrow tile.
     */
    // if the last item has an arrow property, add the tile at the index of the arrow property to the stepsToMove array
    //if (stepsToMove[stepsToMove.length - 1]?.arrow) {
    //   const arrowIndex = stepsToMove[stepsToMove.length - 1].arrow;
    //   stepsToMove.push(tiles[arrowIndex]);
    // }

    // for each item in stepsToMove, call centerViewportOnTile with the x and y coordinates of the tile
    stepsToMove.forEach((tile, index) => {
      //stagger the calls to centerViewportOnTile by 0.5 seconds
      setTimeout(() => {
        setCurrentTile(() => {
          centerViewportOnTile(tile.x, tile.y);
          return tile.id;
        });
        // if we are on the last step, process the action for the given tile
        if (index === stepsToMove.length - 1) {
          setTimeout(() => {
            if (shouldDisplayHalfNextRollOverlay) {
              setShouldDisplayHalfNextRollOverlay(false);
            }
            processTileAction(tile);
          }, 500);
        }
      }, 800 * index);
    });
  }, [
    centerViewportOnTile,
    currentTile,
    processTileAction,
    shouldDisplayHalfNextRollOverlay,
  ]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Play</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div
        ref={viewportRef}
        style={{
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
          position: 'relative',
          touchAction: 'none', // Prevent default touch behavior
        }}
      >
        <Player x={x} y={y} />
        <picture style={{ zIndex: 100 }}>
          <source type="image/avif" srcSet={background + 'avif'} />
          <img
            src={background + '.jpeg'}
            alt="Board"
            width={2896 * GLOBAL_ZOOM}
            height={4096 * GLOBAL_ZOOM}
            style={{ zIndex: 100 }}
          />
        </picture>
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1800,
            color: '#fff',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              margin: '10px',
              zIndex: 1800,
            }}
          >
            <div
              style={{
                top: 0,
                left: 0,
                padding: '10px',
                margin: 0,
                color: '#fff',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              <button type="button" onClick={onFullScreenClick}>
                Fullscreen
              </button>
              <hr />
              <button type="button" disabled={isMoving} onClick={diceRoll}>
                Dice Roll
              </button>
              <p>
                Current Tile:{' '}
                {currentTile === -1 ? currentTile : currentTile + 1}
              </p>
              <p>
                Current move:
                {typeof currentMove === 'number' ? ' ' + currentMove : ' N/A'}
              </p>
              {remainder && <p>Remainder: {remainder}</p>}
            </div>
          </div>
          {shouldDisplayDoubleNextEdgeOverlay && <DoubleNextEdgeOverlay />}
          {shouldDisplayHalfNextRollOverlay && <HalfNextRollOverlay />}
          {shouldDisplayTaskOverlay && (
            <TaskOverlay
              onMoveCompletion={() => {
                setShouldDisplayTaskOverlay(() => {
                  setIsMoving(false);
                  return false;
                });
              }}
            />
          )}
          {shouldDisplayRestOverlay && (
            <RestOverlay
              onMoveCompletion={() => {
                setShouldDisplayRestOverlay(false);
                setIsMoving(false);
              }}
            />
          )}
          {shouldDisplayEdgeOverlay && (
            <EdgeOverlay
              edges={
                edgesForCurrentMove *
                (shouldDisplayDoubleNextEdgeOverlay ? 2 : 1)
              }
              onMoveCompletion={() => {
                setShouldDisplayEdgeOverlay(false);
                setShouldDisplayDoubleNextEdgeOverlay(false);

                if (tiles[currentTile].arrow) {
                  const arrowIndex = tiles[currentTile].arrow;
                  const arrowTile = tiles[arrowIndex];
                  setCurrentTile(arrowTile.id);
                  centerViewportOnTile(arrowTile.x, arrowTile.y, true);
                  setTimeout(() => setIsMoving(false), 500);
                } else {
                  setIsMoving(false);
                }
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Board;
