import React, { useCallback, useEffect, useState, useRef } from 'react';

export const EdgeOverlay = ({ edges = 1, onMoveCompletion = () => {} }) => {
  const [edgesRemaining, setEdgesRemaining] = useState(edges);
  const btnRef = useRef();

  const handleClick = useCallback(() => {
    setEdgesRemaining((prev) => (prev === 0 ? 0 : prev - 1));
  }, []);

  useEffect(() => {
    if (edgesRemaining === 0) {
      // handle any additional actions, otherwise we can hide the overlay
      onMoveCompletion();
    }
  }, [edgesRemaining, onMoveCompletion]);

  useEffect(() => {
    btnRef.current.focus();
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <h1>EdgeOverlay</h1>
      <p>Edges remaining: {edgesRemaining}</p>
      <button ref={btnRef} onClick={handleClick}>
        Edge
      </button>
    </div>
  );
};
