import React, { useCallback, useEffect, useState, useRef } from 'react';

export const TaskOverlay = ({
  taskDescription = '',
  onMoveCompletion = () => {},
}) => {
  const btnRef = useRef();

  const [taskComplete, setTaskComplete] = useState(false);

  const handleClick = useCallback(() => {
    setTaskComplete(() => true);
  }, [setTaskComplete]);

  useEffect(() => {
    if (taskComplete === true) {
      // handle any additional actions, otherwise we can hide the overlay
      onMoveCompletion();
    }
  }, [taskComplete, onMoveCompletion]);

  useEffect(() => {
    btnRef.current.focus();
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(248, 225, 75, 0.5)',
      }}
    >
      <h1>Task Overlay</h1>
      {taskDescription.length > 0 && (
        <>
          <p>Task description:</p>
          <p>{taskDescription}</p>
        </>
      )}
      <button ref={btnRef} onClick={handleClick}>
        Complete Task
      </button>
    </div>
  );
};
