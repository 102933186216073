import React, { useEffect, useRef, useCallback, useState } from 'react';
import { animate } from 'framer-motion';
import { GLOBAL_ZOOM } from './constants';

const SCALE = 0.5;
const width = 1024,
  height = 1024;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const Player = ({ x = 0, y = 0 }) => {
  const prevX = usePrevious(x);
  const prevY = usePrevious(y);

  const [playerX, setPlayerX] = useState(x);
  const [playerY, setPlayerY] = useState(y);

  const onXUpdate = useCallback((latest) => setPlayerX(latest), [setPlayerX]);
  const onYUpdate = useCallback((latest) => setPlayerY(latest), [setPlayerY]);

  useEffect(() => {
    if (prevX !== x) {
      animate(prevX, x, {
        onUpdate: onXUpdate,
        ease: 'anticipate',
        from: prevX,
        duration: 0.5,
      });
    }
  }, [prevX, x, onXUpdate]);

  useEffect(() => {
    if (prevY !== y) {
      animate(prevY, y, {
        onUpdate: onYUpdate,
        ease: 'anticipate',
        from: prevY,
        duration: 0.5,
      });
    }
  }, [prevY, y, onYUpdate]);

  return (
    <picture>
      <source
        type="image/avif"
        srcSet={process.env.PUBLIC_URL + '/player.avif'}
      />
      <img
        src={process.env.PUBLIC_URL + '/player.png'}
        alt="Player"
        width={1024 * SCALE * GLOBAL_ZOOM}
        height={1024 * SCALE * GLOBAL_ZOOM}
        style={{
          zIndex: 200,
          pointerEvents: 'none',
          position: 'absolute',
          top: (playerY - height * SCALE + 100) * GLOBAL_ZOOM,
          left: (playerX - (width * SCALE) / 2 - 4) * GLOBAL_ZOOM,
        }}
      />
    </picture>
  );
};
