import React from 'react';

export const HalfNextRollOverlay = () => {
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(95, 205, 66, 0.5)',
      }}
    >
      <h1>Half Next Roll</h1>
    </div>
  );
};
